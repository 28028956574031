
.v-btn-toggle {
    width: 100%;
    border-radius: var(--btn-border-radius-xxl);
    overflow: hidden;
    > .v-btn.v-btn {
        margin: 0;
        border-radius: 0;
        opacity: 1;
        border-width: var(--btn-outline-border-width);
        background-color: #fff !important;
        border-color: inherit;
        &--active {
            color: #fff !important;
            background-color: inherit !important;
            &:before {
                opacity: 0;
            }
        }
        &.v-size--xx-large {
            &:first-child {
                border-top-left-radius: var(--btn-border-radius-xxl);
                border-bottom-left-radius: var(--btn-border-radius-xxl);
            }
            &:last-child {
                border-top-right-radius: var(--btn-border-radius-xxl);
                border-bottom-right-radius: var(--btn-border-radius-xxl);
            }
        }
    }
}
